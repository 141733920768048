import { useState, useEffect, useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config"; // Adjust path as needed

const useUserData = (userId) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(!!userId); // Set loading based on userId existence

  const fetchUserData = useCallback(async () => {
    if (!userId) {
      setLoading(false); // Stop loading if no userId
      return;
    }

    setLoading(true);
    try {
      const userRef = doc(db, "user", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const newData = userSnap.data();

        // ✅ Only update state if data has changed
        if (JSON.stringify(newData) !== JSON.stringify(userData)) {
          setUserData(newData);
        }
      } else {
        console.error("User does not exist!");
        setUserData(null);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    setLoading(false);
  }, [userId, userData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return { userData, loading, refetchUser: fetchUserData };
};

export default useUserData;
