import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/loader/Loader";
import useUserOrders from "../../CustomHooks/useUserOrders";
import {
  FaShippingFast,
  FaBoxOpen,
  FaCheckCircle,
  FaTimesCircle,
  FaUndoAlt,
  FaSearch,
  FaChevronRight,
  FaChevronLeft,
  FaInfoCircle,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { removeFromOrders } from "../../redux/IchthusSlice";

const OrderHistory = () => {
  const {
    orders,
    loading,
    totalPages,
    fetchOrders,
    handleSearch,
    handleStatusFilter,
    paginate,
    currentPage,
    orderStatusFilter,
  } = useUserOrders();

  const dispatch = useDispatch();
  const reduxOrders = useSelector((state) => state?.orebiReducer?.orders) || [];
  const count = reduxOrders.reduce((total, order) => total + order.quantity, 0);

  const formatAmount = (amount) =>
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);

  useEffect(() => {
    fetchOrders(1, "", orderStatusFilter || "");
  }, [fetchOrders, orderStatusFilter]);

  const [showExtraStatuses, setShowExtraStatuses] = useState(false);

  const mainStatuses = [
    {
      label: "To Ship",
      icon: <FaShippingFast />,
    },
    { label: "To Receive", icon: <FaBoxOpen /> },
    { label: "Completed", icon: <FaCheckCircle /> },
  ];

  const extraStatuses = [
    { label: "Cancelled", icon: <FaTimesCircle /> },
    { label: "Return", icon: <FaUndoAlt /> },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "To Ship":
        return "text-orange-500";
      case "To Receive":
        return "text-blue-500";
      case "Completed":
        return "text-green-500";
      case "Cancelled":
      case "Return":
        return "text-red-500";
      default:
        return "text-gray-500";
    }
  };

  const handleToShipClick = () => {
    dispatch(removeFromOrders());
    handleStatusFilter("To Ship");
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 3;

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => paginate(1)}
          className={`p-2 ${
            currentPage === 1 ? "bg-blue-500 text-white" : "bg-gray-300"
          } rounded mx-1`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <span key="start-ellipsis" className="mx-1">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`p-2 ${
            i === currentPage ? "bg-blue-500 text-white" : "bg-gray-300"
          } rounded mx-1`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <span key="end-ellipsis" className="mx-1">
            ...
          </span>
        );
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => paginate(totalPages)}
          className={`p-2 ${
            currentPage === totalPages
              ? "bg-blue-500 text-white"
              : "bg-gray-300"
          } rounded mx-1`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="container  px-2 py-8">
      <ToastContainer />

      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold text-gray-900 dark:text-white mb-6 text-center">
        My Purchase
      </h2>
      <div className="mb-8 flex flex-col items-center space-y-4">
        {orderStatusFilter && (
          <div className="relative w-full md:w-80">
            <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search by Product Name..."
              onChange={(e) => handleSearch(e.target.value)}
              className="input border-2 border-gray-600 px-5 py-2 pl-12 rounded-lg w-full focus:ring-2"
            />
          </div>
        )}

        <div className="flex items-center space-x-2">
          {showExtraStatuses && (
            <button
              onClick={() => setShowExtraStatuses(false)}
              className="p-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 transition"
            >
              <FaChevronLeft />
            </button>
          )}

          <div className="flex flex-nowrap overflow-auto justify-center gap-2">
            {(showExtraStatuses ? extraStatuses : mainStatuses).map(
              ({ label, icon }) => (
                <button
                  key={label}
                  onClick={
                    label === "To Ship"
                      ? handleToShipClick
                      : () => handleStatusFilter(label)
                  }
                  className="flex flex-col md:flex-row items-center justify-center gap-1 md:gap-2 px-4 py-2 rounded-lg font-medium transition-all shadow-md bg-gray-200 text-gray-700 hover:bg-gray-300 relative w-[80px] md:w-auto"
                >
                  <span className="text-lg md:text-xl">{icon}</span>
                  <span className="text-xs md:text-sm font-semibold">
                    {label}
                  </span>
                  {label === "To Ship" && count > 0 && (
                    <span className="absolute top-0 -right-2 text-xs w-5 h-5 flex items-center justify-center rounded-full bg-orange-500 text-white">
                      {count}
                    </span>
                  )}
                </button>
              )
            )}
          </div>

          {!showExtraStatuses && (
            <button
              onClick={() => setShowExtraStatuses(true)}
              className="p-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 transition"
            >
              <FaChevronRight />
            </button>
          )}
        </div>
      </div>
      {orderStatusFilter && (
        <div
          className="bg-teal-100 border-l-4 border-teal-500 text-teal-900 px-3 py-2 rounded flex items-center shadow-md text-sm mb-10"
          role="alert"
        >
          <FaInfoCircle className="text-teal-500 mr-2" />
          <div>
            <p className="font-semibold">Order Cancellation</p>
            <p>
              To cancel your order, visit the contact us page and provide the
              reference number.
            </p>
          </div>
        </div>
      )}
      {orderStatusFilter && (
        <div className="relative overflow-x-auto shadow-md rounded-lg bg-white dark:bg-gray-800">
          <table className="w-full text-xs text-left text-gray-600 dark:text-gray-400">
            <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-300">
              <tr>
                {[
                  "Ref # / Date / Status",
                  "Product",
                  "Qty",
                  "Price",
                  "Payment",
                ].map((header, i) => (
                  <th
                    key={i}
                    className="px-2 py-2 font-semibold text-gray-800 dark:text-gray-200"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan="5" className="px-2 py-2 text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {!loading && orders.length === 0 && orderStatusFilter && (
                <tr>
                  <td colSpan="5" className="px-2 py-2 text-center">
                    No orders found.
                  </td>
                </tr>
              )}
              {!loading &&
                orders.length > 0 &&
                orders.map((order, index) => (
                  <tr key={index} className="border-b dark:border-gray-700">
                    <td className="px-2 py-2 font-medium text-gray-900 dark:text-white text-[10px]">
                      <div className="flex flex-col">
                        <span>{order.referenceNumber}</span>
                        <span className="text-gray-500 dark:text-gray-400">
                          {new Date(
                            order.timestamp.seconds * 1000
                          ).toLocaleDateString()}
                        </span>
                        <span
                          className={`font-semibold ${getStatusColor(
                            order.orderStatus
                          )}`}
                        >
                          {order.orderStatus}
                        </span>
                      </div>
                    </td>
                    <td className="px-2 py-2 align-top text-[10px]">
                      <div className="flex flex-col">
                        {order.products.map((product, i) => (
                          <span key={i} className="truncate">
                            {product.name.length > 20
                              ? `${product.name.substring(0, 20)}...`
                              : product.name}{" "}
                            ({product.colors})
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-2 py-2 align-top text-[10px]">
                      <div className="flex flex-col">
                        {order.products.map((product, i) => (
                          <span key={i}>{product.quantity}</span>
                        ))}
                      </div>
                    </td>
                    <td className="px-2 py-2 align-top text-[10px]">
                      <div className="flex flex-col">
                        {order.products.map((product, i) => (
                          <span key={i} className="flex items-center">
                            <span className="text-[8px] mr-0.5">₱</span>
                            {formatAmount(product.price * product.quantity)}
                          </span>
                        ))}
                        <div className="border-t mt-1 pt-1 font-bold text-gray-900 dark:text-white flex items-center">
                          <span className="text-[8px] mr-0.5">₱</span>
                          {formatAmount(order?.finalPrice)}
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 font-medium text-gray-900 dark:text-white text-[10px]">
                      {order.status}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {orderStatusFilter && (
        <div className="flex justify-center m-10">{renderPageNumbers()}</div>
      )}
    </div>
  );
};

export default OrderHistory;
