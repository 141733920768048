import React from "react";
import { useData } from "../../../CustomHooks/DataProvider";
import {
  clearIndexedDbPersistence,
  getFirestore,
  terminate,
} from "firebase/firestore";

const clearFirestoreCache = async () => {
  localStorage.clear();

  try {
    const db = getFirestore();
    await terminate(db); // Close Firestore connections
    await clearIndexedDbPersistence(db); // Now clear the cache
    console.log("Cache cleared successfully");
  } catch (error) {
    console.error("Error clearing cache:", error);
  }
};

const DataControl = () => {
  const { refetchCollection, refetchAllData, loadingCollection } = useData();

  const collections = [
    "Shippingfees",
    "banner",
    "bannerBottom",
    "brand",
    "category",
    "contacts",
    "coupons",
    "featuredProducts",
    "features",
    "history",
    "logos",
    "ordered",
    "productImages",
    "products",
    "promises",
    "serial",
    "stores",
    "user",
    "yearProduct",
  ];

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="max-w-3xl mx-auto bg-white p-6 shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Data Control
        </h2>
        <button
          onClick={() => refetchAllData(true)}
          className="w-full bg-blue-500 text-white py-2 rounded-lg mb-4 hover:bg-blue-600 transition"
        >
          Refresh All Data
        </button>
        <button
          onClick={clearFirestoreCache}
          className="w-full bg-red-500 text-white py-2 rounded-lg mb-4 hover:bg-red-600 transition"
        >
          Clear Firestore Cache
        </button>
        <div className="grid grid-cols-2 gap-4">
          {collections.map((collection) => (
            <button
              key={collection}
              onClick={() => refetchCollection(collection)}
              className={`py-2 px-4 rounded-lg text-white ${
                loadingCollection === collection
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600 transition"
              }`}
              disabled={loadingCollection === collection}
            >
              {loadingCollection === collection
                ? `Refreshing ${collection}...`
                : `Refresh ${collection}`}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataControl;
