import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import navigation
import OrderHistory from "../../pages/orderHistory/OrderHistory";
import { Mail, MapPin, Phone, User } from "lucide-react";
import { useSelector } from "react-redux";
import {
  selectFullName,
  selectEmail,
  selectPhone,
  selectAddress,
  selectBarangay,
  selectCity,
  selectProvince,
  selectRegion,
  selectImgUrl,
  selectUserID,
} from "../../redux/IchthusSlice";
import EditProfile from "../../pages/Account/EditProfile";

const MobileProfile = () => {
  const [showEditProfile, setShowEditProfile] = useState(false);
  const navigate = useNavigate(); // Initialize navigate
  const fullName = useSelector(selectFullName);
  const email = useSelector(selectEmail);
  const phone = useSelector(selectPhone);
  const address = useSelector(selectAddress);
  const barangay = useSelector(selectBarangay);
  const city = useSelector(selectCity);
  const province = useSelector(selectProvince);
  const region = useSelector(selectRegion);
  const imgUrl = useSelector(selectImgUrl);
  const userID = useSelector(selectUserID);

  // Redirect if screen is md or larger
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        navigate("/"); // Redirect to home if md (768px) and above
      }
    };

    handleResize(); // Check on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [navigate]);

  const closeModal = () => setShowEditProfile(false);
  const openModal = () => setShowEditProfile(true);

  return (
    <div className="w-full flex justify-center">
      <div className="bg-white p-2 md:p-10 rounded-lg shadow-2xl max-h-screen overflow-y-auto flex flex-col items-center w-full max-w-3xl mdl:aspect-[9/16] mdl:h-auto">
        <div className="flex justify-between items-center w-full mb-6">
          <h2 className="text-2xl md:text-3xl font-bold text-orange-500">
            Profile Details
          </h2>
        </div>
        <div className="rounded-lg p-6 flex flex-col sml:flex-row items-center w-full max-w-2xl gap-6">
          <div className="flex-shrink-0">
            <img
              src={imgUrl || "/default-avatar.png"}
              alt={fullName || "User Avatar"}
              className="w-28 h-28 sm:w-32 sm:h-32 rounded-full object-cover border-4 border-orange-500"
            />
            <div className="mt-2">
              <button
                onClick={openModal}
                className="bg-gray-500 hover:bg-orange-600 text-white font-bold py-1 px-3 text-sm rounded-full transition duration-300"
              >
                Edit Profile
              </button>
            </div>
          </div>

          <div className="text-gray-800 w-full space-y-3">
            <p className="text-sm font-semibold text-gray-600 flex items-center gap-2">
              <User className="w-5 h-5 text-orange-500" />
              <span className="text-lg text-black">Name:</span>{" "}
              <span className="block truncate w-44">{fullName}</span>
            </p>
            <p className="text-sm font-semibold text-gray-600 flex items-center gap-2">
              <Mail className="w-5 h-5 text-orange-500" />
              <span className="text-lg text-black">Email:</span>{" "}
              <span className="block truncate w-44">{email}</span>
            </p>
            <p className="text-sm font-semibold text-gray-600 flex items-center gap-2">
              <Phone className="w-5 h-5 text-orange-500" />
              <span className="text-lg text-black ">Phone:</span>
              <span className="block truncate w-44">{phone}</span>
            </p>
            <p className="text-lg font-semibold flex items-start gap-2 mt-4">
              <MapPin className="w-5 h-5 text-orange-500 mt-1" />
              <span className="text-black">Address:</span>
            </p>
            <span className="block text-gray-600 text-sm mt-1 ">
              {`${region}, ${province}, ${city}, ${barangay}, ${address}`}
            </span>
          </div>
        </div>

        {/* Show EditProfile Modal */}
        {showEditProfile && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10 mt-10">
            <div className="bg-white pr-2 pt-10 rounded-lg max-h-screen overflow-y-auto shadow-lg">
              <EditProfile userId={userID} onClose={closeModal} />
            </div>
          </div>
        )}

        <OrderHistory />
      </div>
    </div>
  );
};

export default MobileProfile;
