import React, { useState, lazy, Suspense } from "react";
import {
  FaUser,
  FaCalendarAlt,
  FaSignOutAlt,
  FaFolder,
  FaAngleLeft,
  FaAngleRight,
  FaArchive,
  FaChartBar,
  FaBookOpen,
  FaPhone,
  FaTicketAlt,
  FaMapPin,
  FaFlag,
  FaPaintBrush,
  FaChevronDown,
  FaChevronUp,
  FaRegFlag,
} from "react-icons/fa";
import logo from "../../assets/images/logo.png"; // Import your image here
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase/config";
import Loader from "../../components/loader/Loader";
import ParentComponent from "../../components/Admin/ProductImage/ParentComponent";
import { persistor } from "../../redux/store";
import { useDispatch } from "react-redux";
import { REMOVE_ACTIVE_USER } from "../../redux/IchthusSlice";
import DataControl from "../../components/Admin/DataControl/DataControl";
import { useData } from "../../CustomHooks/DataProvider";

// Lazy load components
const Dashboard = lazy(() =>
  import("../../components/Admin/Dashboard/Dashboard")
);
const Users = lazy(() => import("../../components/Admin/User/Users"));
const Products = lazy(() => import("../../components/Admin/Products/Products"));
const Categories = lazy(() =>
  import("../../components/Admin/Categories/Categories")
);
const Transactions = lazy(() =>
  import("../../components/Admin/Transactions/Transactions")
);
const Brands = lazy(() => import("../../components/Admin/Brands/Brands"));
const Inventory = lazy(() =>
  import("../../components/Admin/Inventory/Inventory")
);
const Contacts = lazy(() => import("../../components/Admin/Contacts/Contacts"));
const Coupons = lazy(() => import("../../components/Admin/Coupons/Coupons"));
const Shippingfees = lazy(() =>
  import("../../components/Admin/Shippingfees/Shippingfees")
);
const Banners = lazy(() => import("../../components/Admin/Banners/Banners"));
const BannersBottom = lazy(() =>
  import("../../components/Admin/BannersBottom/BannersBottom")
);
const YearProduct = lazy(() =>
  import("../../components/Admin/YearProduct/YearProduct")
);
const FeaturedProductSlider = lazy(() =>
  import("../../components/Admin/FeaturedProductSlider/FeaturedProductSlider")
);

const HeroSection = lazy(() =>
  import("../../components/Admin/About/HeroSection")
);
const Logos = lazy(() => import("../../components/Admin/Logos/AllLogo"));

const Admin = () => {
  const [open, setOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState(
    localStorage.getItem("selectedMenu") || "Dashboard"
  );

  // Update localStorage whenever the menu changes
  const { fetchSpecificCollection } = useData();

  const [designHomepageOpen, setDesignHomepageOpen] = useState(false);
  const [designAboutOpen, setDesignAboutOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Add this line
  const Menus = [
    { title: "Dashboard", Icon: FaChartBar, component: <Dashboard /> },
    { title: "Users", Icon: FaUser, component: <Users /> },
    {
      title: "Categories",
      Icon: FaBookOpen,
      gap: true,
      component: <Categories />,
    },

    { title: "Brands", Icon: FaFolder, component: <Brands /> },
    {
      title: "Products",
      Icon: FaCalendarAlt,
      component: <Products />,
    },
    {
      title: "ProductImage",
      Icon: FaCalendarAlt,
      component: <ParentComponent />,
    },
    {
      title: "Transactions",
      Icon: FaChartBar,
      component: <Transactions />,
    },
    {
      title: "Inventory",
      Icon: FaArchive,
      component: <Inventory />,
    },
    {
      title: "Contact Us",
      Icon: FaPhone,
      component: <Contacts />,
    },
    {
      title: "Coupons",
      Icon: FaTicketAlt,
      component: <Coupons />,
    },
    {
      title: "Shippingfees",
      Icon: FaMapPin,
      component: <Shippingfees />,
    },
    {
      title: "PartnerLogos",
      Icon: FaMapPin,
      component: <Logos />,
    },
    {
      title: "DataControl",
      Icon: FaChartBar,
      component: <DataControl />,
    },
    {
      title: "Homepage Design",
      Icon: FaPaintBrush,
      subMenu: [
        {
          title: "Banner",
          Icon: FaFlag,
          component: <Banners />,
        },
        {
          title: "BannersBottom",
          Icon: FaRegFlag,
          component: <BannersBottom />,
        },
        {
          title: "YearProduct",
          Icon: FaRegFlag,
          component: <YearProduct />,
        },
        {
          title: "FeaturedSlider",
          Icon: FaRegFlag,
          component: <FeaturedProductSlider />,
        },
      ],
    },
    {
      title: "About Design",
      Icon: FaPaintBrush,
      subMenu: [
        {
          title: "Hero Section",
          Icon: FaFlag,
          component: <HeroSection />,
        },
      ],
    },
    { title: "Logout", Icon: FaSignOutAlt, gap: true },
  ];

  const handleMenuClick = (title) => {
    if (title === "Logout") {
      logoutUser();
    } else if (title === "Homepage Design") {
      setDesignHomepageOpen(!designHomepageOpen);
    } else if (title === "About Design") {
      setDesignAboutOpen(!designAboutOpen);
    } else {
      setSelectedMenu(title);

      // Fetch "Users" data when the "Users" menu is clicked
      if (title === "Users") {
        fetchSpecificCollection("user");
      }

      // Fetch "Products" data when the "Products" menu is clicked
      if (title === "Products") {
        fetchSpecificCollection("products");
      }
    }
  };

  const logoutUser = () => {
    signOut(auth)
      .then(() => {
        toast.success("Logout Successfully.");
        dispatch(REMOVE_ACTIVE_USER());
        navigate("/");
        persistor.purge();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="fixed z-50 h-screen overflow-y-auto bg-gray-900 text-gray-300 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
        <div
          className={`duration-300 ease-in-out ${
            open ? "w-72" : "w-20"
          } bg-gray-800 p-5 pt-8 relative`}
        >
          <div
            className="absolute top-1/2 transform -translate-y-1/2 right-4 translate-x-1/2 cursor-pointer w-6 h-6 bg-orange-500 border-dark-purple border-2 rounded-full flex items-center justify-center transition-all duration-300"
            onClick={() => setOpen(!open)}
          >
            {open ? <FaAngleLeft /> : <FaAngleRight />}
          </div>
          <Link to="/">
            <div className="flex items-center gap-x-4">
              <img
                src={logo}
                alt="logo"
                className={`w-12 h-12 cursor-pointer transition-transform duration-500 ${
                  open && "rotate-360"
                }`}
              />
              {open && (
                <h1 className="text-white font-medium text-lg">
                  Ichthus Technology
                </h1>
              )}
            </div>
          </Link>
          <ul className="mt-6">
            {Menus.map((Menu, index) => (
              <div key={index}>
                <li
                  className={`flex items-center justify-between text-sm p-2 rounded-md transition-colors hover:bg-gray-700 ${
                    Menu.gap ? "mt-9" : "mt-2"
                  } ${selectedMenu === Menu.title && "bg-gray-700"}`}
                  onClick={() => handleMenuClick(Menu.title)}
                >
                  <div className="flex items-center gap-x-4">
                    <Menu.Icon />
                    <span
                      className={`${
                        !open && "hidden"
                      } transition-all duration-200`}
                    >
                      {Menu.title}
                    </span>
                  </div>
                  {Menu.subMenu && open && (
                    <span>
                      {(Menu.title === "Homepage Design" &&
                        designHomepageOpen) ||
                      (Menu.title === "About Design" && designAboutOpen) ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronDown />
                      )}
                    </span>
                  )}
                </li>
                {Menu.subMenu &&
                  ((Menu.title === "Homepage Design" && designHomepageOpen) ||
                    (Menu.title === "About Design" && designAboutOpen)) && (
                    <ul className="ml-8">
                      {Menu.subMenu.map((subMenu, subIndex) => (
                        <li
                          key={subIndex}
                          className={`flex items-center p-2 text-sm rounded-md transition-colors hover:bg-gray-700 ${
                            selectedMenu === subMenu.title && "bg-gray-700"
                          }`}
                          onClick={() => handleMenuClick(subMenu.title)}
                        >
                          <subMenu.Icon />
                          <span
                            className={`${
                              !open && "hidden"
                            } transition-all duration-200`}
                          >
                            {subMenu.title}
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
            ))}
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`flex-1 p-7 overflow-y-auto transition-all duration-300 ${
          open ? "ml-72" : "ml-20"
        }`}
      >
        <Suspense fallback={<Loader />}>
          {/* Render selected component based on selectedMenu */}
          {Menus.find((menu) => menu.title === selectedMenu)?.component ||
            Menus.flatMap((menu) => menu.subMenu || []).find(
              (subMenu) => subMenu.title === selectedMenu
            )?.component || (
              <h1 className="text-2xl font-semibold text-white">Home Page</h1>
            )}
        </Suspense>
      </div>
    </div>
  );
};

export default Admin;
