import React, { createContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsLoggedIn,
  selectUserID,
  selectPhone,
  selectEmail,
  selectFullName,
  selectAddress,
  selectBarangay,
  selectCity,
  selectProvince,
  selectRegion,
  selectImgUrl,
  selectAdmin,
} from "./IchthusSlice";

const INITIAL_STATE = {
  currentUser: null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userID = useSelector(selectUserID);
  const phone = useSelector(selectPhone);
  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const address = useSelector(selectAddress);
  const barangay = useSelector(selectBarangay);
  const city = useSelector(selectCity);
  const province = useSelector(selectProvince);
  const region = useSelector(selectRegion);
  const imgUrl = useSelector(selectImgUrl);
  const admin = useSelector(selectAdmin);
  const dispatch = useDispatch();

  // Construct `currentUser` from Redux state
  const currentUser = useMemo(() => {
    if (!isLoggedIn) return null;

    return {
      id: userID,
      phone,
      email,
      fullName,
      address,
      barangay,
      city,
      province,
      region,
      imgUrl,
      admin,
    };
  }, [
    isLoggedIn,
    userID,
    phone,
    email,
    fullName,
    address,
    barangay,
    city,
    province,
    region,
    imgUrl,
    admin,
  ]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
