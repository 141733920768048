import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPayment = () => {
  const navigate = useNavigate();

  return (
    <div className="payment-success-container flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-lg bg-white shadow-md rounded-lg p-8 text-center">
        <h1 className="text-3xl font-bold text-green-600">
          Payment Successful!
        </h1>
        <p className="mt-4 text-lg text-gray-700">Thanks for your purchase!</p>
        <p className="mt-2 text-sm text-gray-500">see you again...</p>
        <button
          onClick={() => navigate("/")}
          className="mt-6 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
        >
          View Order History
        </button>
      </div>
    </div>
  );
};

export default SuccessPayment;
