import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/config";
import {
  saveCollectionToDB,
  getCollectionFromDB,
} from "../CustomHooks/indexedDB";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  // State variables for data, loading states, and loading collection
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingCollection, setLoadingCollection] = useState(null);

  // Array of collection names to fetch from Firestore
  const collections = useMemo(
    () => [
      "Shippingfees",
      "banner",
      "bannerBottom",
      "brand",
      "category",
      "contacts",
      "coupons",
      "featuredProducts",
      "features",
      "history",
      "logos",
      "ordered",
      "promises",
      "serial",
      "stores",
      "user",
      "products",
      "yearProduct",
    ],
    []
  );

  // Function to fetch a specific collection, checking IndexedDB cache first
  const fetchSpecificCollection = useCallback(
    async (collectionName) => {
      if (data[collectionName] && data[collectionName].length > 0) {
        console.log(
          `🟢 Cached data for ${collectionName} already set in state.`
        );
        return;
      }

      setLoadingCollection(collectionName);
      console.log(`🔄 Fetching ${collectionName} (Checking Cache First)...`);

      try {
        const cachedData = await getCollectionFromDB(collectionName);

        if (cachedData && cachedData.length > 0) {
          console.log(`🟢 Using cached data for ${collectionName}`);
          setData((prevData) => ({
            ...prevData,
            [collectionName]: cachedData,
          }));
          setLoadingCollection(null);
          return;
        }

        console.log(
          `🔄 No cache found. Fetching ${collectionName} from Firestore...`
        );
        const snapshot = await getDocs(collection(db, collectionName));
        const fetchedData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setData((prevData) => ({
          ...prevData,
          [collectionName]: fetchedData,
        }));

        await saveCollectionToDB(collectionName, fetchedData);
        console.log(`✅ ${collectionName} fetched and cached.`);
      } catch (error) {
        console.error(`❌ Error fetching ${collectionName}:`, error.message);
      } finally {
        setLoadingCollection(null);
      }
    },
    [data] // Removed data from dependency array
  );
  // Reference to the global settings document in Firestore
  const globalSettingsRef = useMemo(() => doc(db, "settings", "global"), []);

  // Function to update the last updated timestamp in global settings
  const updateGlobalRefetchTimestamp = useCallback(async () => {
    const globalSnapshot = await getDoc(globalSettingsRef);
    const lastUpdated = globalSnapshot.exists()
      ? globalSnapshot.data().lastUpdated?.toMillis() || 0
      : 0;

    const now = Date.now();
    const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;

    if (now - lastUpdated < oneWeekInMs) {
      console.log("⏳ Skipping timestamp update (already recent).");
      return;
    }

    await setDoc(
      globalSettingsRef,
      { lastUpdated: serverTimestamp() },
      { merge: true }
    );
  }, [globalSettingsRef]);

  // Function to refetch all data, checking for a global refetch trigger
  const refetchAllData = useCallback(
    async (forceRefetch = false) => {
      // Add forceRefetch parameter
      console.log(
        forceRefetch
          ? "🔄 Force Refetching all data from Firestore..."
          : "🔄 Checking Firestore for global refetch trigger..."
      );
      const globalSnapshot = await getDoc(globalSettingsRef);
      const lastUpdated = globalSnapshot.exists()
        ? globalSnapshot.data().lastUpdated?.toMillis() || 0
        : 0;

      const now = Date.now();
      const oneWeekInMs = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

      if (!forceRefetch && now - lastUpdated < oneWeekInMs) {
        console.log("⏳ Skipping refetch (last update within a week).");
        return;
      }

      console.log(
        forceRefetch
          ? "🔄 Force Refetching all data from Firestore..."
          : "🔄 Refetching all data from Firestore..."
      );
      setLoading(true);
      const allData = {};

      const fetchPromises = collections.map(async (collectionName) => {
        try {
          const snapshot = await getDocs(collection(db, collectionName));
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          allData[collectionName] = docs;
          await saveCollectionToDB(collectionName, docs);
          console.log(
            `✅ Refetched ${docs.length} documents for ${collectionName}`
          );
        } catch (error) {
          console.error(
            `❌ Error refetching ${collectionName}:`,
            error.message
          );
        }
      });

      await Promise.all(fetchPromises);
      setData(allData);
      setLoading(false);

      console.log("✅ Data refetched and updated in IndexedDB.");

      await updateGlobalRefetchTimestamp();
    },
    [collections, globalSettingsRef, updateGlobalRefetchTimestamp]
  );

  // useEffect to fetch initial data and set up a real-time listener for global settings
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const allData = {};

      const fetchPromises = collections.map(async (collectionName) => {
        try {
          const cachedData = await getCollectionFromDB(collectionName);

          if (cachedData) {
            console.log(`🟢 Using cached data for ${collectionName}`);
            allData[collectionName] = cachedData;
          } else {
            console.log(
              `🔄 Fetching fresh data from Firestore for ${collectionName}...`
            );
            const snapshot = await getDocs(collection(db, collectionName));
            const docs = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            allData[collectionName] = docs;
            await saveCollectionToDB(collectionName, docs);
            console.log(
              `✅ Fetched ${docs.length} documents from Firestore for ${collectionName}`
            );
          }
        } catch (error) {
          console.error(`❌ Error fetching ${collectionName}:`, error.message);
        }
      });

      await Promise.all(fetchPromises);
      setData(allData);
      setLoading(false);
    };

    fetchData();

    // Real-time listener for global settings
    const unsubscribe = onSnapshot(globalSettingsRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const lastUpdated = docSnapshot.data().lastUpdated?.toMillis() || 0;
        const now = Date.now();
        const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;

        if (now - lastUpdated > oneWeekInMs) {
          console.log(
            "⏳ Global refetch timestamp indicates weekly update needed."
          );
          refetchAllData();
        } else {
          console.log(
            "Global refetch timestamp is within a week. Nothing to refetch."
          );
        }
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, [collections, refetchAllData, globalSettingsRef]);

  // Function to refetch a specific collection or document
  const refetchCollection = async (collectionName, id = null) => {
    setLoadingCollection(collectionName);
    console.log(
      `🔄 Force refetching ${collectionName} ${id ? `ID: ${id}` : "all"}...`
    );

    try {
      let fetchedData;

      if (id) {
        // Fetch a specific document
        const docRef = doc(db, collectionName, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          fetchedData = { id: docSnap.id, ...docSnap.data() };

          setData((prevData) => ({
            ...prevData,
            [collectionName]: prevData[collectionName]
              ? prevData[collectionName].map((doc) =>
                  doc.id === id ? fetchedData : doc
                )
              : [fetchedData],
          }));

          await saveCollectionToDB(collectionName, fetchedData);
        } else {
          console.error(`❌ Document ${id} not found in ${collectionName}`);
        }
      } else {
        // Fetch the entire collection
        const snapshot = await getDocs(collection(db, collectionName));
        fetchedData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log(
          `✅ Force refetched all ${fetchedData.length} documents from ${collectionName}`
        );

        setData((prevData) => ({
          ...prevData,
          [collectionName]: fetchedData,
        }));

        await saveCollectionToDB(collectionName, fetchedData);
      }
    } catch (error) {
      console.error(`❌ Error refetching ${collectionName}:`, error.message);
    } finally {
      setLoadingCollection(null);
    }
  };

  // Provide the context value to the children components
  return (
    <DataContext.Provider
      value={{
        data,
        loading,
        refetchCollection,
        refetchAllData,
        fetchSpecificCollection,
        loadingCollection,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to access the DataContext
export const useData = () => useContext(DataContext);
