import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import Select from "react-select";
import Loader from "../../components/loader/Loader";
import { db, storage } from "../../firebase/config";
import { profile } from "../../assets/images";
import barangay from "barangay";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_USER } from "../../redux/IchthusSlice";
import useUserData from "../../CustomHooks/useFetchUserData";
import { useNavigate } from "react-router-dom";

const EditProfile = ({ userId, onClose }) => {
  const { userData, loading, refetchUser } = useUserData(userId);
  const [userImage, setUserImage] = useState(null);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [provincesOptions, setProvincesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [barangaysOptions, setBarangaysOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedBarangay, setSelectedBarangay] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      navigate("/signin");
    }
  }, [userId, navigate]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    const fetchRegions = () => {
      const regionsData = barangay();
      const options = regionsData.map((region) => ({
        value: region,
        label: region,
      }));
      setRegionsOptions(options);
    };

    fetchRegions();
  }, []);

  useEffect(() => {
    if (!userData) return;
    setFullName(userData.fullName || "");
    setPhone(userData.phone || "");
    setAddress(userData.address || "");
    setEmail(userData.email || "");
    setUserImage(userData.imgUrl || profile);
    setAdmin(userData.admin || false);

    setSelectedRegion(
      userData.region
        ? { value: userData.region, label: userData.region }
        : null
    );
    setSelectedProvince(
      userData.province
        ? { value: userData.province, label: userData.province }
        : null
    );
    setSelectedCity(
      userData.city ? { value: userData.city, label: userData.city } : null
    );
    setSelectedBarangay(
      userData.barangay
        ? { value: userData.barangay, label: userData.barangay }
        : null
    );
  }, [userData]);

  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption);
    const provincesData = barangay(selectedOption.value);
    const options = provincesData.map((province) => ({
      value: province,
      label: province,
    }));
    setProvincesOptions(options);
    setSelectedProvince(null);
    setSelectedCity(null);
    setCitiesOptions([]);
    setBarangaysOptions([]);
  };

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption);
    const citiesData = barangay(selectedRegion.value, selectedOption.value);
    const options = citiesData.map((city) => ({
      value: city,
      label: city,
    }));
    setCitiesOptions(options);
    setSelectedCity(null);
    setBarangaysOptions([]);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    const barangaysData = barangay(
      selectedRegion.value,
      selectedProvince.value,
      selectedOption.value
    );
    const options = barangaysData.map((barangay) => ({
      value: barangay,
      label: barangay,
    }));
    setBarangaysOptions(options);
    setSelectedBarangay(null);
  };

  const handleBarangayChange = (selectedOption) => {
    setSelectedBarangay(selectedOption);
  };

  const updateUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const updateData = {
      fullName,
      phone,
      address,
      email,
      admin, // Ensure 'admin' is retrieved from state
      imgUrl: userImage || "",
    };

    if (selectedRegion?.value) updateData.region = selectedRegion.value;
    if (selectedProvince?.value) updateData.province = selectedProvince.value;
    if (selectedCity?.value) updateData.city = selectedCity.value;
    if (selectedBarangay?.value) updateData.barangay = selectedBarangay.value;

    try {
      if (userImage && typeof userImage !== "string") {
        const storageRef = ref(
          storage,
          `userImages/${Date.now() + userImage.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, userImage);

        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            toast.error("Image upload failed: " + error.message);
            setIsLoading(false);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              updateData.imgUrl = downloadURL;

              const userRef = doc(db, "user", userId);
              await updateDoc(userRef, updateData);

              dispatch(
                SET_ACTIVE_USER({
                  fullName: updateData.fullName || "",
                  email: updateData.email || "",
                  phone: updateData.phone || "",
                  address: updateData.address || "",
                  barangay: updateData.barangay || "",
                  city: updateData.city || "",
                  province: updateData.province || "",
                  region: updateData.region || "",
                  imgUrl: updateData.imgUrl || userImage || "",
                  userID: userId || "",
                  admin: admin || "",
                })
              );

              setIsLoading(false);
              refetchUser();
              toast.success("User updated successfully");
              onClose();
            } catch (error) {
              setIsLoading(false);
              toast.error("Error updating user: " + error.message);
            }
          }
        );
      } else {
        const userRef = doc(db, "user", userId);
        await updateDoc(userRef, updateData);

        dispatch(
          SET_ACTIVE_USER({
            fullName: updateData.fullName || "",
            email: updateData.email || "",
            phone: updateData.phone || "",
            address: updateData.address || "",
            barangay: updateData.barangay || "",
            city: updateData.city || "",
            province: updateData.province || "",
            region: updateData.region || "",
            imgUrl: updateData.imgUrl || userImage || "",
            userID: userId || "",
            admin: admin || "",
          })
        );

        setIsLoading(false);
        toast.success("User updated successfully");
        onClose();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error updating user: " + error.message);
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className="relative w-full pt-4 py-4 px-12 ">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 p-2 text-white hover:text-gray-700 bg-red-500 rounded-full transition transform hover:scale-110 mt-10 "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-3xl mb-4 mt-10">Edit Profile</h2>
        <p className="mb-4">Update user information.</p>
        <form onSubmit={updateUser}>
          <div className="flex justify-center">
            <div className="h-48 w-48 rounded-lg overflow-hidden mt-5">
              <img
                className="object-cover h-full w-full"
                src={
                  userImage
                    ? typeof userImage === "string"
                      ? userImage
                      : URL.createObjectURL(userImage)
                    : profile
                }
                alt="Profile"
              />
            </div>
          </div>
          <div className="mt-5">
            <span>User Image</span>
            <input
              id="userImage"
              type="file"
              className="border border-gray-400 py-1 px-2 w-full"
              onChange={(e) => setUserImage(e.target.files[0])}
            />
          </div>
          <div className="mt-5">
            <span>Full Name</span>
            <input
              id="fullName"
              type="text"
              placeholder="First, MI., and LastName"
              className="border border-gray-400 py-1 px-2 w-full"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="mt-5">
            <span>Phone</span>
            <input
              id="phone"
              type="text"
              placeholder="0975*******"
              className="border border-gray-400 py-1 px-2 w-full"
              value={phone}
              onChange={(e) => {
                const input = e.target.value
                  .replace(/\D/g, "")
                  .substring(0, 11);
                setPhone(input);
              }}
              pattern="\d*"
              maxLength={11}
              required
            />
          </div>

          <div className="mt-5">
            <span>Email</span>
            <input
              id="email"
              type="email"
              placeholder="Email...."
              className="border border-gray-400 py-1 px-2 w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              readOnly
            />
          </div>
          <div className="mt-5">
            <label>Region</label>
            <Select
              options={regionsOptions}
              value={selectedRegion}
              onChange={handleRegionChange}
              placeholder="Select Region"
              className="w-full"
            />
          </div>
          <div className="mt-5">
            <label>Province</label>
            <Select
              options={provincesOptions}
              value={selectedProvince}
              onChange={handleProvinceChange}
              placeholder="Select Province"
              className="w-full"
              isDisabled={!selectedRegion}
            />
          </div>
          <div className="mt-5">
            <label>City</label>
            <Select
              options={citiesOptions}
              value={selectedCity}
              onChange={handleCityChange}
              placeholder="Select City"
              className="w-full"
              isDisabled={!selectedProvince}
            />
          </div>
          <div className="mt-5">
            <label>Barangay</label>
            <Select
              options={barangaysOptions}
              value={selectedBarangay}
              onChange={handleBarangayChange}
              placeholder="Select Barangay"
              className="w-full"
              isDisabled={!selectedCity}
            />
          </div>
          <div className="mt-5">
            <label> Street Name, Building, House No.</label>
            <input
              id="address"
              type="text"
              placeholder="123 Main Street"
              className="border border-gray-400 py-1 px-2 w-full"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-orange-500 hover:bg-orange-700 text-white w-full py-3 text-center mt-4"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
