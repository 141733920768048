import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  CACHE_SIZE_UNLIMITED,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance"; // ✅ Remove isSupported

const firebaseConfig = {
  apiKey: "AIzaSyA1RYxTzQug9tmAC1XUtJnKjlL6Fwe-eo0",
  authDomain: "ichthusweb-ef3cd.firebaseapp.com",
  projectId: "ichthusweb-ef3cd",
  storageBucket: "ichthusweb-ef3cd.appspot.com",
  messagingSenderId: "967594303895",
  appId: "1:967594303895:web:645fea9955aaa98baa24d2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore with local cache and multi-tab synchronization
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    tabManager: persistentMultipleTabManager(),
  }),
});

export const auth = getAuth(app);
export const storage = getStorage(app);

// ✅ Directly initialize Performance Monitoring
if (process.env.NODE_ENV === "production") {
  getPerformance(app);
}

export default app;
