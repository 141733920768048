import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  isLoggedIn: false,
  email: null,
  userName: null,
  userID: null,
  fullName: null,
  phone: null,
  address: null,
  barangay: null,
  city: null,
  admin: null,
  province: null,
  region: null,
  imgUrl: null, // Add this
  products: [],
  checkedBrands: [],
  checkedCategories: [],
  firestoreData: {},
  orders: [],
};

export const IchthusSlice = createSlice({
  name: "Ichthus",
  initialState,
  reducers: {
    SET_ACTIVE_USER: (state, action) => {
      const {
        fullName,
        email,
        phone,
        address,
        barangay,
        city,
        province,
        region,
        imgUrl,
        userID,
        admin,
      } = action.payload;
      console.log("Full Name:", fullName);
      console.log("Email:", email);
      console.log("Phone:", phone);
      console.log("Address:", address);
      console.log("Barangay:", barangay);
      console.log("City:", city);
      console.log("Province:", province);
      console.log("Region:", region);
      console.log("Image URL:", imgUrl);
      console.log("User ID:", userID);
      console.log("Admin:", admin);

      state.isLoggedIn = true;
      state.email = email;
      state.userName = fullName || "No Name";
      state.userID = userID;
      state.fullName = fullName;
      state.admin = admin;
      state.phone = phone;
      state.address = address;
      state.barangay = barangay;
      state.city = city;
      state.province = province;
      state.region = region;
      state.imgUrl = imgUrl; // Store the imgUrl
    },
    REMOVE_ACTIVE_USER: (state) => {
      state.isLoggedIn = false;
      state.email = null;
      state.userName = null;
      state.userID = null;
      state.fullName = null;
      state.phone = null;
      state.address = null;
      state.barangay = null;
      state.city = null;
      state.province = null;
      state.region = null;
      state.imgUrl = null; // Reset imgUrl
    },
    // Add this to your IchthusSlice
    setFirestoreData: (state, action) => {
      const { collectionName, data } = action.payload;
      state[collectionName] = data; // Dynamically store data for different collections
    },
    resetFirestoreData: (state, action) => {
      const { collectionName } = action.payload;
      state[collectionName] = [];
    },

    addToOrders: (state, action) => {
      const { id } = action.payload;
      const existingOrder = state.orders.find((order) => order.id === id);

      if (existingOrder) {
        existingOrder.quantity += 1;
      } else {
        state.orders.push({ id, quantity: 1 });
      }
    },

    removeFromOrders: (state) => {
      state.orders = []; // Ensure it's always an array
    },

    // Cart reducers
    addToCart: (state, action) => {
      const item = state.products.find(
        (item) =>
          item.id === action.payload.id && item.colors === action.payload.colors
      );

      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push({ ...action.payload });
      }

      toast.success("Product added to cart");
    },

    deleteItem: (state, action) => {
      const { id, color } = action.payload;
      state.products = state.products.filter(
        (item) => !(item.id === id && item.colors === color)
      );
      toast.error("Product removed from cart");
    },

    increaseQuantity: (state, action) => {
      const { id, color } = action.payload;
      const item = state.products.find(
        (item) => item.id === id && item.colors === color
      );
      if (item) {
        item.quantity++;
      }
    },

    decreaseQuantity: (state, action) => {
      const { id, color } = action.payload;
      const item = state.products.find(
        (item) => item.id === id && item.colors === color
      );
      if (item && item.quantity > 1) {
        item.quantity--;
      }
    },

    resetCart: (state) => {
      state.products = [];
    },

    // Brand and Category reducers
    toggleBrand: (state, action) => {
      const brand = action.payload;
      const isBrandChecked = state.checkedBrands.some((b) => b.id === brand.id);
      if (isBrandChecked) {
        state.checkedBrands = state.checkedBrands.filter(
          (b) => b.id !== brand.id
        );
      } else {
        state.checkedBrands.push(brand);
      }
    },
    toggleCategory: (state, action) => {
      const category = action.payload;
      const isCategoryChecked = state.checkedCategories.some(
        (c) => c.id === category.id
      );
      if (isCategoryChecked) {
        state.checkedCategories = state.checkedCategories.filter(
          (c) => c.id !== category.id
        );
      } else {
        state.checkedCategories.push(category);
      }
    },
  },
});

// Export actions and selectors
export const {
  SET_ACTIVE_USER,
  REMOVE_ACTIVE_USER,
  addToCart,
  addToOrders,
  removeFromOrders,
  increaseQuantity,
  decreaseQuantity,
  deleteItem,
  resetCart,
  toggleBrand,
  toggleCategory,
  setFirestoreData,
  resetFirestoreData,
} = IchthusSlice.actions;

export const selectIsLoggedIn = (state) => state.orebiReducer.isLoggedIn;
export const selectEmail = (state) => state.orebiReducer.email;
export const selectUserName = (state) => state.orebiReducer.userName;
export const selectUserID = (state) => state.orebiReducer.userID;
export const selectFullName = (state) => state.orebiReducer.fullName;
export const selectAdmin = (state) => state.orebiReducer.admin;
export const selectImgUrl = (state) => state.orebiReducer.imgUrl;
export const selectPhone = (state) => state.orebiReducer.phone;
export const selectAddress = (state) => state.orebiReducer.address;
export const selectBarangay = (state) => state.orebiReducer.barangay;
export const selectCity = (state) => state.orebiReducer.city;
export const selectProvince = (state) => state.orebiReducer.province;
export const selectRegion = (state) => state.orebiReducer.region;
export const selectCartProducts = (state) => state.orebiReducer.products;
export const selectOrders = (state) => state.orebiReducer.orders;

export default IchthusSlice.reducer;
