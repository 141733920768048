import { useState, useEffect, useCallback, useRef } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { db } from "../firebase/config";

const useUserOrders = () => {
  const userID = useSelector((state) => state.orebiReducer.userID);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderStatusFilter, setOrderStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // ✅ Store last document for pagination
  const lastVisibleRef = useRef(null);

  // ✅ Fetch total count with filters
  useEffect(() => {
    if (!userID || !orderStatusFilter) return;

    const fetchTotalOrdersCount = async () => {
      try {
        let q = query(
          collection(db, "ordered", userID, "orders"),
          where("orderStatus", "==", orderStatusFilter),
          where("status", "in", ["Cash on Delivery", "Paid"])
        );

        if (searchQuery) {
          q = query(
            q,
            where("name", ">=", searchQuery),
            where("name", "<=", searchQuery + "\uf8ff")
          );
        }

        const snapshot = await getCountFromServer(q);
        setTotalPages(Math.ceil(snapshot.data().count / itemsPerPage));
      } catch (error) {
        console.error("Error fetching total orders count:", error);
      }
    };

    fetchTotalOrdersCount();
  }, [userID, orderStatusFilter, searchQuery, itemsPerPage]);

  // ✅ Fetch Orders with Proper Status Filtering and Pagination
  const fetchOrders = useCallback(
    async (pageNumber = 1, search = "", orderStatus = orderStatusFilter) => {
      if (!userID || !orderStatus) {
        setOrders([]);
        return;
      }

      setLoading(true);
      console.log(
        `[READ] Fetching orders for Page ${pageNumber}, Status: ${orderStatus}`
      );

      try {
        let q = query(
          collection(db, "ordered", userID, "orders"),
          where("orderStatus", "==", orderStatus),
          where("status", "in", ["Cash on Delivery", "Paid"]),
          orderBy("timestamp", "desc"),
          limit(itemsPerPage)
        );

        if (search) {
          q = query(
            collection(db, "ordered", userID, "orders"),
            where("orderStatus", "==", orderStatus),
            where("status", "in", ["Cash on Delivery", "Paid"]),
            where("name", ">=", search),
            where("name", "<=", search + "\uf8ff"),
            orderBy("name"),
            limit(itemsPerPage)
          );
        }

        // ✅ Apply cursor-based pagination
        if (pageNumber > 1 && lastVisibleRef.current) {
          q = query(q, startAfter(lastVisibleRef.current));
        }

        const snapshot = await getDocs(q);

        console.log(`Order Status: ${orderStatus}`);
        console.log(
          `[READ] Page: ${pageNumber}, Documents Read: ${snapshot.docs.length}`
        );

        const fetchedOrders = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // ✅ Set the last document as the new cursor
        lastVisibleRef.current =
          snapshot.docs[snapshot.docs.length - 1] || null;

        setOrders(fetchedOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    },
    [userID, itemsPerPage, orderStatusFilter]
  );

  // ✅ Fetch orders when page, search, or status filter changes
  useEffect(() => {
    if (userID) {
      fetchOrders(currentPage, searchQuery, orderStatusFilter);
    }
  }, [currentPage, searchQuery, orderStatusFilter, userID, fetchOrders]);

  // ✅ Handle search
  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
    lastVisibleRef.current = null; // Reset cursor for new search
  };

  // ✅ Handle status filter
  const handleStatusFilter = (orderStatus) => {
    setOrderStatusFilter(orderStatus);
    setCurrentPage(1);
    lastVisibleRef.current = null; // Reset cursor for new filter
  };

  // ✅ Handle pagination click
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return {
    orders,
    loading,
    totalPages,
    fetchOrders,
    handleSearch,
    handleStatusFilter,
    paginate,
    currentPage,
    orderStatusFilter,
  };
};

export default useUserOrders;
